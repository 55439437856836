import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Seo from '../components/seo'
import pathspliter from '../functions/pathspliter'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

class Classes extends Component {

  render() {
    return (
      <div>
        <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
      <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer} path={this.props.path} menu={this.props.pageContext.menu}/>
      <Breadcrumb title={this.props.pageContext.title} path={pathspliter(this.props.path)}/>
        <main>
      <Container>
        <section className="classes py-6">
          {this.props.data.drupal.programs.entities.map((item,i) => {
            return (
              <Row key={item.entityId} className={`section-120 ${i%2!==0 ? "reverse": ""}` }>
                  <Col md={6}>
                  <div className="wpb_wrapper">
               <h2 className="section-title">{item.title}</h2>
               <div dangerouslySetInnerHTML={{ __html: item.body.processed }} ></div>


                    </div>
                  </Col>
                  <Col md={6}>
                  <figure className="wpb_wrapper vc_figure">
       <div className="vc_single_image-wrapper   vc_box_border_grey">
       <Img fluid={item.fieldProgramImage.entity.thumbnail.imageFile.childImageSharp.fluid} className="vc_single_image-img attachment-full" alt={item.fieldProgramImage.entity.thumbnail.alt} />
       </div>
              </figure>
                  </Col>

              </Row>
            )
          })}




        </section>

        <section className="pb-6">
           <div dangerouslySetInnerHTML={{ __html: this.props.data.drupal.programslist.entities[0].body.processed }} ></div>
        </section>
      </Container>
      </main>

      <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>

    </div>
    );
  }

}

export default Classes;


export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {
      programs: nodeQuery(filter: {conditions: [{field: "type", value: "program", language:$langcode}]}) {
      entities(language:$langcode) {

          ... on DRUPAL_NodeProgram {
          entityLanguage {
            id
           }
          title
      body {
       processed
      }
      fieldProgramImage {
        entity {
          thumbnail {
            url
            alt
            imageFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 600, maxHeight:400) {
                  ...GatsbyImageSharpFluid_withWebp
                  src
                }
              }
            }
          }
        }
      }
 }

}
}


programslist: nodeQuery(filter: {conditions: [{field: "type", value: "programs_list", language:$langcode}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeProgramsList {

      body {
        processed
      }

    }
  }
}

metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "programs_list"}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeProgramsList {

      entityMetatags {
        value
        key
      }

    }
  }
}






}
  }
`
